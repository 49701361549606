<template>
  <!-- MODAL REPLACE SPOTS -->
  <b-modal
    id="replace-spots"
    ref="replace-spots"
    size="md"
    :title="$t('table.replaceSpots')"
    :ok-disabled="$v.$invalid || isBusy"
    :ok-title="$t('table.replaceSpots')"
    :cancel-title="$t('table.cancel')"
    @ok.prevent="replaceSpots"
    @hidden="clearFields"
    @show="load"
  >
    <form v-if="currentMediaPlan" class="d-flex flex-wrap w-100 justify-content-between" @submit.stop.prevent="checkIfValidThenEnter">
      <input type="submit" value="Submit" class="hidden-submit" />
      <div class="d-flex gap-2 w-100">
        <b-form-group :label="$t('booking.replaceEvery')" label-for="input-1-replace">
          <b-form-input id="input-1-replace" v-model.number="replaceEvery" type="number" min="1" required style="width: 140px"></b-form-input>
        </b-form-group>
        <b-form-group class="w-75" :label="$t('booking.selectCommercial')">
          <MultiSelect
            v-model.trim="commercialFirst"
            label="name_with_id"
            track-by="id"
            :options="uniqueCommercialsList"
            :placeholder="$t('booking.selectCommercial')"
          ></MultiSelect>
        </b-form-group>
      </div>

      <b-form-group :label="$t('booking.with')" class="ml-auto w-100">
        <MultiSelect
          v-model.trim="commercialSecond"
          label="name_with_id"
          track-by="id"
          :options="commercialSecondList"
          :placeholder="$t('booking.selectCommercial')"
          :disabled="!commercialFirst"
        ></MultiSelect>
      </b-form-group>

      <b-form-group style="width: 49%" :label="$t('table.start_at')" label-for="datepicker-buttons-from">
        <datepicker-wrapper
          id="datepicker-buttons-from"
          v-model="dateFrom"
          required
          :min="currentMediaPlan.date_from"
          :max="currentMediaPlan.date_to"
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
        />
      </b-form-group>
      <b-form-group style="width: 49%" :label="$t('table.end_at')" label-for="datepicker-buttons-to">
        <datepicker-wrapper
          id="datepicker-buttons-to"
          v-model="dateTo"
          required
          :min="getMaxDate(dateFrom, currentMediaPlan.date_from)"
          :max="currentMediaPlan.date_to"
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
        />
      </b-form-group>

      <b-form-group :label="$t('table.start_at')" label-for="input-6" style="width: 49%">
        <BroadcastTimePicker id="input-6" v-model="timeFrom" :min-time="minTime" :max-time="maxTime" required :interval-mode="true" />
      </b-form-group>
      <b-form-group :label="$t('table.end_at')" label-for="input-7" style="width: 49%">
        <BroadcastTimePicker id="input-7" v-model="timeTo" :min-time="minTime" :max-time="maxTime" required :interval-mode="true" />
      </b-form-group>
    </form>
  </b-modal>
  <!-- MODAL REPLACE SPOTS -->
</template>

<script>
import { mapGetters } from 'vuex';
import { required, minValue } from 'vuelidate/lib/validators';
import MultiSelect from '@/components/MultiSelect';
import errorsHandler from '@/utils/errorsHandler';
import DatepickerWrapper from '@/components/DatepickerWrapper.vue';
import getMinOrMaxDate from '@/mixins/getMinOrMaxDate';
import setTimeOfSpot from '@/mixins/setTimeOfSpot';
import BroadcastTimePicker from '@/components/BroadcastTimePicker';
import { getYear } from '@/api/apiServices';

export default {
  name: 'ModalReplaceSpots',
  components: { MultiSelect, DatepickerWrapper, BroadcastTimePicker },
  mixins: [getMinOrMaxDate, setTimeOfSpot],
  props: {
    currentMediaPlan: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      replaceEvery: 1,
      commercialFirst: '',
      commercialSecond: '',
      commercialSecondList: [],
      dateFrom: '',
      dateTo: '',
      uniqueCommercialsList: [],
      isBusy: false,
    };
  },
  validations: {
    replaceEvery: { required, minValue: minValue(1) },
    commercialFirst: { required },
    commercialSecond: { required },
    dateFrom: { required },
    dateTo: { required },
    timeFrom: { required },
    timeTo: { required },
  },
  computed: {
    ...mapGetters({
      isLocale: 'isLocale',
      isThemeHeader: 'isTheme',
    }),
  },
  watch: {
    commercialFirst() {
      if (this.commercialFirst) {
        this.commercialSecondList = this.uniqueCommercialsList.filter((el) => el.id !== this.commercialFirst.id);
        if (this.commercialSecond && this.commercialFirst.id === this.commercialSecond.id) this.commercialSecond = '';
      }
    },
  },

  methods: {
    clearFields() {
      this.replaceEvery = 1;
      this.commercialFirst = '';
      this.commercialSecond = '';
      this.commercialSecondList = [];
      this.dateFrom = '';
      this.dateTo = '';
      this.timeFrom = this.minTime;
      this.timeTo = this.maxTime;
      this.uniqueCommercialsList = [];
    },

    hideModalReplaceSpots() {
      this.$bvModal.hide('replace-spots');
    },

    async replaceSpots() {
      this.isBusy = true;
      const formData = {
        nth: this.replaceEvery,
        mediaplan_id: this.currentMediaPlan.id,
        commercial_replacement_id: this.commercialFirst.id,
        commercial_replacer_id: this.commercialSecond.id,
        date_start_at: this.dateFrom,
        date_end_at: this.dateTo,
        start_interval: this.timeFrom,
        end_interval: this.timeTo,
      };
      await this.$store.dispatch('POST_REPLACE_SPOTS', {
        formData,
        handler: (res) => {
          this.$notify({
            type: 'success',
            title: this.$i18n.t('alert.replaceSpots'),
            text:
              this.$i18n.t('alert.found') +
              ': ' +
              res.data.data.count_replacements +
              '. ' +
              this.$i18n.t('alert.replaced') +
              ': ' +
              res.data.data.count_replaced,
          });
          this.hideModalReplaceSpots();
          this.$emit('updateData');
          if (this.$route?.name === 'booking') this.$emit('updateWgrp');
        },
        handlerError: (errors) => {
          errorsHandler(errors, this.$notify);
        },
      });
      this.isBusy = false;
    },
    checkIfValidThenEnter() {
      if (!this.$v.$invalid) this.replaceSpots();
    },

    loadDates() {
      this.dateFrom = this.currentMediaPlan.date_from;
      this.dateTo = this.currentMediaPlan.date_to;
      this.uniqueCommercialsList = [];
      this.uniqueCommercialsList = [
        ...new Map(
          this.currentMediaPlan.commercials.map(function (item) {
            item.name_with_id = `[${item['id']}] ${item['name']}`;

            return [item['id'], item];
          })
        ).values(),
      ];
    },
  },
};
</script>
