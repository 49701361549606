import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      timeFrom: 0,
      timeTo: 0,
    };
  },
  computed: {
    ...mapGetters({
      channel: 'getModalEditChannels',
    }),
    minTime() {
      if (this.channel.channel_settings == null) return;
      return this.channel?.channel_settings[this.currentMediaPlan.date_from.substr(0, 4)]?.start_interval;
    },
    maxTime() {
      return this.minTime + 24 * 3600 - 1;
    },
  },
  watch: {
    channel() {
      this.setTime();
    },
    loadDates() {
      this.setTimeByChannel();
    },
    $bvModal() {
      this.setTimeByChannel();
    },
  },
  mounted() {
    this.setTime();
  },
  methods: {
    load() {
      this.loadDates();
      this.setTimeByChannel();
    },
    async setTime() {
      this.timeFrom = this.minTime;
      this.timeTo = this.maxTime;
    },
    async setTimeByChannel() {
      if (this.currentMediaPlan == null) return;
      if (this.channel == '' || this.currentMediaPlan.channel_id != this.channel?.id) {
        await this.$store.dispatch('GET_CHANNELS_ID', this.currentMediaPlan.channel_id);
      }
      this.timeFrom = this.minTime;
      this.timeTo = this.maxTime;
    },
  },
};
